import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import Loader from "react-loader-spinner"
import { CurrentUserAsync } from "../services/parse-bridge"
import { Colors } from "../colors/Colors"

const PrivateRoute = ({ children, path }) => {
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)

    useEffect(() => {
        loadUser()
    }, [])

    const loadUser = async () => {
        let currentUser = await CurrentUserAsync()

        if (currentUser == null) {
            setLoading(false)
            return
        }
        setUser(currentUser)
        setLoading(false)
    }

    if (
        !loading &&
        !user &&
        typeof window !== "undefined" &&
        window.location.href !== path
    ) {
        navigate(path)
        return null
    }
    if (loading) {
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh'}}>
                <Loader type="Oval" color={Colors.gold} height={30} width={30} />
            </div>
        )
    }

    return <>{children}</>
}
export default PrivateRoute