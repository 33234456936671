import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import PrivateRoute from "../components/private-route"
import { SectionTitle } from "../components/Section-Title"
import { getAllReviews } from "../services/parse-bridge"
import moment from "moment"
import ReactStars from "react-rating-stars-component"
import { Colors } from "../colors/Colors"

export default function ReviewsPage() {
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadReviews()
  }, [])

  const loadReviews = async () => {
    const reviews = await getAllReviews()
    setReviews(reviews)
    setLoading(false)
  }

  return (
    <Layout dark>
      <PrivateRoute path="/login">
        <Container>
          <SectionTitle>Reviews</SectionTitle>
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Rating</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {reviews.map((review, index) => (
                  <tr>
                    <td>
                      {moment(review.createdAt)
                        .format("ddd Do MMM, YYYY, h:mmA")
                        .toString()}
                    </td>
                    <td>{review.name}</td>
                    <td style={{ width: 150, whiteSpace: "nowrap" }}>
                      <ReactStars
                        count={5}
                        value={review.rating}
                        size={20}
                        activeColor={Colors.gold}
                      />
                    </td>
                    <td>{review.comment}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </Container>
      </PrivateRoute>
    </Layout>
  )
}

const Container = styled.section`
  min-height: 50vh;
  padding: 20vh 20px;
`
const TableContainer = styled.div`
  overflow-x: auto;
`
const Table = styled.table`
  font-family: "Articulat";
`
const THeader = styled.th``
